<template>
<div class="px-50 pt-1">

  <div class="mb-1">
    <div class="d-flex justify-content-between w-100 align-items-end">
      <label>Text</label>
      <span class="small text-secondary">Inicial text to apply regex to</span>
    </div>
    
    <b-form-input v-model="tempText" @input="parseReg1()"/>
    <div class="text-secondary small"> *Original text is not altered</div>
  </div>

  <div class="mb-1">
    <div class="d-flex justify-content-between w-100 align-items-end">
      <label>Regex</label>
      <span class="small text-secondary">Regular Expression to find in Text</span>
    </div>
    
    <b-form-input v-model="tempRegex" @input="parseReg1()"/>
  </div>

  
  <div>
    <div class="d-flex justify-content-between w-100 align-items-end">
      <label>Replace</label>
      <span class="small text-secondary">What to replace matched text with</span>
    </div>
    
    <b-form-input v-model="tempValue" @input="parseReg1()"/>
  </div>
  
  

  <div align="center" class="my-2">
    <h5 class="mb-0 border-bottom-success w-fit-content pb-50">
      replace all matches of 
      <b class="text-warning">[ Regex ]</b>
      in <b class="text-info">[ Text ]</b> with <b class="text-danger">[ Replace ]</b>
    </h5>    
    <b-icon icon="arrow-down" variant="success" scale="1.8"/>
    
  </div>
  
  <div>
    <custom-input
      v-if="variables"
      class="my-1"
      :possibleValues="variables"
      v-model="agent.register_destiny"
      advanced
    >
      <template #label>
        Destiny
        
        <span class="float-right text-right mx-25 text-secondary">Destination to store resulted string</span>
      </template>
    </custom-input>
    <div v-else class="mb-2">
      <b-skeleton class="mb-25" width="25%" height="12px" />
      <b-skeleton class="mb-25" width="100%" height="37px" />
      <b-skeleton class="mb-25" width="25%" height="14px" />
    </div>
  </div>
  
</div>
</template>

<script>
import {
  BLink,
  BSkeleton, 
  BFormCheckbox, 
  BButton, 
  BContainer, 
  BCol, 
  BRow, 
  BSidebar, 
  BIcon ,
  BFormInput,
} from "bootstrap-vue";import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "./../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import HelperTooltip from '@/layouts/components/HelperTooltip';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import AgentsMixin from '../AgentsMixin'
import * as icons from 'vue-feather-icons';

export default {
  mixins: [AgentsMixin],

  components: {
    BButton,
    BContainer,
    BCol,
    BRow,
    BSidebar,
    VariablesPanel,
    CustomInput,
    BFormCheckbox,
    BIcon,
    BSkeleton,
    HelperTooltip,
    BLink,
    VuePerfectScrollbar,
    BFormInput,
  },
  props: {
    middlewareID: {
      type: Number,
      required: true,
    },
    agentID: {
      type: Number,
      default: undefined,
    },
    value: {
      type: NormalizedAgent,
      default: undefined
    },
    uuid: {
      type: String,
      default: uuidv4(),
    },
    width: {
      type: String,
      default: "40%"
    },
    agentBlockID: {
      type: [ Number, String ],
      default: 1,
    },
    variables:{
      type: Object,
      default: undefined,
    }
  },
  data() {
    return {
      actionID: 71,
      i18nKey: 'regex_replace',
      defaultAgent: {
        id: null,
        enum_agent_action_id: this.actionID,
        agent_block_id: this.agentBlockID,
        register_1: {
          source: 7,
          value: ""
        },
        register_2: {
          source: 7,
          value: ""
        },
        register_destiny: {
          source: 10,
          value: ""
        },
        execution_order: 1,
        fatal_on_fail: true,
      },

      tempRegex: "",
      tempValue: "",
      tempText: "",
      
      uuidMap: {},

      isSaving: false,
    };
  },
  computed: {
    agent: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    toSaveRegs() {
      return {
        register_1: true,
        register_2: false,
        register_destiny: true,
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
    init() {
      DefaultAgent.fetchAgentByID(this)
    
      if (this.agent.register_1?.value){
        let t = JSON.parse(this.agent.register_1?.value)
        this.tempText = t.text
        this.tempRegex = t.regex
        this.tempValue = t.value
      }
    },
    validate() {
      return DefaultAgent.validate(this)
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }
      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
    parseReg1(){
      let r = {
        text: this.tempText,
        regex: this.tempRegex,
        value: this.tempValue,
      }
      r = JSON.stringify(r)
      this.agent.register_1.source = '7'
      this.agent.register_1.value = r
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
